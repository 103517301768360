<template lang="pug">
app-layout
  v-app.layout-full
    app-nav-drawer(v-model="navDrawer")
    app-header(no-buttons @nav:click="navDrawer = true")

    v-main
      .page-sign-in.pa-6.pb-8
        template(v-if="notRegistered")
          .d-flex.align-center.c-primary.px-0.mb-4(@click="changePhoneOrEmail")
            ui-icon(name="arrow-left" left)
            .t-caption-middle Назад
          .t-h4-mobile(v-html="notRegisteredText")
          ui-btn.page-sign-in__button.mt-6(
            large
            key="btnSignUp"
            outlined
            :to="$path.signUp()"
            width="100%"
          ) Зарегистрироваться

        template(v-else)
          .t-h1-mobile.mb-5.mt-5(v-if="!notRegistered") Вход и регистрация
          v-window(v-model="step")
            v-window-item(
              :value="1"
              disabled
              :reverse-transition="false"
              :transition="false"
            )
              v-text-field.mt-4(
                v-model="phoneOrEmail"
                label="Телефон или электронная почта"
                hide-details="auto"
                key="phoneOrEmail"
                :error-messages="errorMsg('phone')"
                @keyup.enter="checkPhoneOrEmail"
              )
                primary
              ui-btn.mt-8(
                large
                key="btnRequest"
                primary
                width="100%"
                @click="checkPhoneOrEmail"
                :loading="loading"
                :disabled="!disableBtn"
              ) Продолжить

            v-window-item(
              :value="2"
              disabled
              :reverse-transition="false"
              :transition="false"
            )
              template(v-if="smsMethod")
                .t-caption-middle.c-primary.px-0.mb-4(@click="startOver") Изменить номер телефона

                v-text-field(
                  v-model="phoneOrEmail"
                  label="Телефон"
                  hide-details="auto"
                  disabled
                  v-maska="maska.PHONE_VMASK"
                  :error-messages="errors.phone"
                )
                  template(v-if="step === 2" #append)
                    ui-icon.c-primary(name="check2")

                code-verification-form.mt-6(
                  v-model="code"
                  :errors="errors"
                  :phone="phoneOrEmail"
                  @click:resend="sendCode"
                  @submit="login"
                )

              template(v-if="passwordMethod")
                .d-flex.align-center.c-primary.px-0.mb-4(@click="startOver")
                  ui-icon(name="arrow-left" left)
                  .t-caption-middle Войти по номеру телефона

                email-sign-in-form.mt-6(
                  v-model="password"
                  :email="phoneOrEmail"
                  :errors="errors"
                  @clear="startOver"
                  @email="setEmail"
                )

              ui-btn.mt-8(
                large
                key="btnSignIn"
                primary
                width="100%"
                @click="login"
                :loading="loading"
              ) Войти

            p.paragraph-with-border
              span или

        sber-id-btn.mt-3
        sber-business-id-btn.mt-3
        esia-btn.mt-3
        .d-flex.justify-center.mt-5
          ui-icon(name="shield-lock" color="#A0A4A8" left)
          .t-caption-small.c-gray60 Это безопасно и удобно. Ваши данные<br>защищены надёжным шифрованием.
    app-footer(no-banner)
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import AppNavDrawer from '@/components/app/AppNavDrawer';
  import AppHeader from '@/components/app/AppHeader';
  import AppFooter from '@/components/app/AppFooter';

  import { UiBtn, UiIcon } from '@/uikit';
  import CodeVerificationForm from '@/components/auth/CodeVerificationForm.vue';
  import EmailSignInForm from '@/components/auth/EmailSignInForm.vue';
  import SberIdBtn from '@/components/auth/SberIdBtn.vue';
  import SberBusinessIdBtn from '@/components/auth/SberBusinessIdBtn.vue';
  import EsiaBtn from '@/components/auth/EsiaBtn.vue';
  import { maska } from 'maska';

  import { mapGetters, mapActions } from 'vuex';
  import { service as API } from '@/services/auth';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  import { formErrors } from '@/uikit/mixins/formErrors';
  import Constants from '@/uikit/util/constants';
  const logger = createLogger('PageSignIn', 'blue');

  export default {
    name: 'page-sign-in',
    components: {
      AppLayout,
      AppNavDrawer,
      AppHeader,
      AppFooter,
      UiBtn,
      UiIcon,
      CodeVerificationForm,
      EmailSignInForm,
      SberIdBtn,
      SberBusinessIdBtn,
      EsiaBtn,
    },
    directives: {
      maska,
    },
    mixins: [formErrors],

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevPath = from.fullPath;
      });
    },

    data: () => ({
      prevPath: null,
      navDrawer: false,
      step: 1,
      method: undefined,
      code: undefined,
      password: undefined,
      timer: null,
      loading: false,
      phoneOrEmail: undefined,
      phone: undefined,
      email: undefined,
      notRegistered: false,
      maska: Constants,
      redirectToDataRoom: false,
    }),

    computed: {
      smsMethod() {
        return this.method === 'sms';
      },

      passwordMethod() {
        return this.method === 'password';
      },

      disableBtn() {
        return !!this.phoneOrEmail;
      },

      notRegisteredText() {
        return this.method === 'sms'
          ? `Номер ${this.phoneOrEmail}<br>не зарегистрирован`
          : `Электронная почта ${this.phoneOrEmail}<br>не зарегистрирована`;
      },

      ...mapGetters(['currentUser']),
    },

    created() {
      this.initialize();
    },

    methods: {
      initialize() {
        this.phoneOrEmail = undefined;
        this.code = undefined;
        this.password = undefined;
        this.method = undefined;
        this.loading = false;
        this.timer = null;
        this.errors = {};
      },

      validatePhoneOrEmail(value) {
        logger.log('validatePhoneOrEmail', value);
        if (value === undefined || value.length === 0) return true;

        const validEmail = Constants.EMAIL_REGEX.test(value);
        const validPhone = Constants.PHONE_REGEX.test(value);

        return validEmail || validPhone || 'неверный формат телефона или электронной почты';
      },

      getMethod(value) {
        if (Constants.EMAIL_REGEX.test(value)) {
          return 'password';
        } else if (Constants.PHONE_REGEX.test(value)) {
          this.correctPhone();
          return 'sms';
        }
        return undefined;
      },

      changePhoneOrEmail() {
        this.notRegistered = false;
        this.step = 1;
      },

      startOver() {
        this.initialize();
        this.step = 1;
      },

      gotoToLogin() {
        if (this.passwordMethod) {
          this.checkEmail();
        } else if (this.smsMethod) {
          this.sendCode();
        }
      },

      checkPhoneOrEmail() {
        this.clearErrors();
        const validPhoneOrEmail = this.validatePhoneOrEmail(this.phoneOrEmail);
        if (validPhoneOrEmail !== true) {
          logger.log('Невалидный телефон или емейл');
          this.addError(validPhoneOrEmail, 'phone');
          return;
        }
        this.method = this.getMethod(this.phoneOrEmail);
        logger.log('Переходим к логину', this.verifyType);
        this.gotoToLogin(this.method);
      },

      async login() {
        try {
          this.loading = true;

          if (localStorage.getItem('redirectToDataRoom')) {
            this.redirectToDataRoom = true;
          }

          let res = {};
          if (this.method === 'sms') {
            res = await API.authenticateBySms(this.phoneOrEmail, this.code, this.redirectToDataRoom);
          } else if (this.method === 'password') {
            res = await API.authenticateByPassword(this.phoneOrEmail, this.password, this.redirectToDataRoom);
          }
          this.initialize();
          this.signInWithToken({
            token: res.token,
            refreshToken: res.refreshToken,
            user: res.user,
            redirect_uri: res.redirect_uri
          });

          if (res.redirect_uri) {
            window.location.href = res.redirect_uri
          }
          if (this.prevPath) {
            this.$router.push(this.prevPath);
          } else {
            this.$router.push(this.$path.home());
          }
        } catch (error) {
          handleError(error, logger);
          if (error.data && error.data.code === 'invalid_form') {
            this.$notify.error('Не удалось осуществить вход в систему');
            this.extractResponseErrors(error.data, 'phone', 'email', 'code', 'password');
          }
        } finally {
          this.loading = false;
          localStorage.removeItem('redirectToDataRoom');
          this.redirectToDataRoom = false;
        }
      },

      async sendCode() {
        try {
          const data = await API.requestCode(this.phoneOrEmail, true);
          if (data) {
            this.step = 2;
            this.$notify.info('Код отправлен в СМС на указанный номер телефона');
          }
        } catch (error) {
          handleError(error, logger);
          if (
            error.data &&
            error.data.data &&
            error.data.data.phone.error !== 'невозможно отправить код на данный номер'
          ) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(error.data, 'phone');
          }
          if (error?.data.data.phone.error === 'невозможно отправить код на данный номер') {
            this.phoneOrEmail = error.data.data.phone.value;
            this.notRegistered = true;
          }
        }
      },

      correctPhone() {
        // приводим номер к виду +7...
        this.phone = this.phoneOrEmail;

        if (this.phone.startsWith('8')) {
          this.phoneOrEmail = '+7' + this.phone.slice(1);
        } else if (this.phone.startsWith('+8')) {
          this.phoneOrEmail = '+7' + this.phone.slice(2);
        } else if (this.phone.startsWith('9')) {
          this.phoneOrEmail = '+7' + this.phone;
        }

        // записываем в стор телефон для продолжения регистрации
        sessionStorage.setItem('phoneToRegister', this.phone);
      },

      async checkEmail() {
        this.email = this.phoneOrEmail;
        sessionStorage.setItem('emailToRegister', this.email);
        try {
          const data = await API.checkPhoneOrEmail(this.phoneOrEmail);
          if (data) {
            this.step = 2;
          }
        } catch (error) {
          if (error.data.data.phone_or_email.error === 'Электронная почта не зарегистрирована') {
            this.notRegistered = true;
          }
          if (
            error.data.data.phone_or_email.error === 'Данный способ авторизации отключен в профиле'
          ) {
            this.$notify.error(error.data.data.phone_or_email.error);
          }
          handleError(error, logger);
          this.extractResponseErrors(error.data, 'phone');
        }
      },

      setEmail(email) {
        this.phoneOrEmail = email;
      },

      ...mapActions('user', ['signInWithToken']),
    },
  };
</script>

<style lang="scss">
  .page-sign-in {
    background-color: c('white', 'light');

    &__button {
      border: 1px solid c('gray40', 'light') !important;
    }

    .v-text-field__slot label.v-label--active {
      color: #909497 !important;
    }

    .v-text-field.v-input--is-disabled .v-input__slot:before {
      border-image: none !important;
    }
  }

  .paragraph-with-border {
    @include text--small;

    position: relative;
    color: c('text--dimmed', 'light');
    text-align: center;
    margin: 16px 0;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: c('black40', 'light');
    }

    span {
      position: relative;
      padding: 0 0.7em;
      background-color: c('white', 'light');
    }
  }
</style>
